@import 'palette';

@import 'swiper/css';
@import 'swiper/css/pagination';

@import 'ngx-toastr/toastr';
@import 'styles/animations';
@import 'styles/button';
@import 'styles/dialog';
@import 'styles/hotfixes';
@import 'styles/input';
@import 'styles/media';
@import 'styles/page';
@import 'styles/scroll';
@import 'styles/skeleton';
@import 'styles/table';
@import 'styles/typography';
@import 'styles/variables';
@import 'styles/fw2svg';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: transparent;
}

* {
  box-sizing: border-box;
}
