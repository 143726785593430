a[role='button'] {
  cursor: pointer;
}

.primary {
  border-color: var(--second-bg) !important;
  color: var(--main-color) !important;
  background-color: var(--second-bg) !important;
  padding: 10px !important;
  border-radius: 30px !important;
  width: 100% !important;
}

.border-main {
  width: 100% !important;
  background-color: transparent !important;
  border: 1px solid var(--second-bg) !important;
  text-align: center !important;
  color: var(--second-bg) !important;
  padding: 10px !important;
  border-radius: 30px !important;
}

.mdc-button__label {
  i {
    color: inherit;
  }
}

.buttons {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  &.end {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.full {
    button {
      width: 100%;
    }
  }
}
