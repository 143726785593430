@import './animations';

mat-form-field {
  --mat-form-field-container-height: 44px;
  width: 100%;
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 21px;
    }
    .mat-mdc-form-field-infix {
      --mat-form-field-container-vertical-padding: 6px;
      .mat-mdc-select {
        --mat-select-trigger-text-line-height: 32px;
      }
    }
    .mat-mdc-form-field-input-control {
      &:not(.mat-mdc-form-field-textarea-control) {
        height: 32px;
      }
      &.mat-mdc-form-field-textarea-control {
        padding: var(--mat-form-field-container-vertical-padding) 0;
      }
    }
    &.mdc-text-field--outlined
      .mdc-notched-outline--upgraded
      .mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY(-28px)
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    }
  }
  .mat-mdc-form-field-error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 44px;
    padding: 10px;
    .mat-mdc-button-touch-target {
      width: 44px;
      height: 44px;
    }
  }

  &.mat-focused {
    button {
      color: var(--mdc-outlined-text-field-focus-label-text-color);
    }
  }

  &.mat-form-field-invalid {
    button {
      color: var(--mdc-outlined-text-field-error-label-text-color);
    }
  }

  input[type='file'] {
    display: none;
  }

  .image-preview {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    img {
      height: 100%;
      max-height: 35px;
    }
  }
  & + mat-form-field {
    margin-top: 0.5rem;
  }
}

form {
  .buttons {
    margin-top: 1rem;
  }
}
