:root {
  font-size: calc(10px + 0.3125vw);
  --ts: all 0.3s ease-in-out;
  --br: 0.5rem;
  --elastic: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  --main-color: #0a4d9b;
  --text-color: #5e6a65;
  --btn-text-color: #ffffff;
  --second-bg: #ffffff;
  --text-color-two: #ffffff;
  --secondary-bg: #eeeeee;
  --line-color: #dbdbdb;
  --bg-div-services: #f0f3f5;
  --bg-tercery: #f0f8ff;
  --bg-figures: #0c4d9b48;
  --yellow-color: #f2af4c;
  --green-color: #5dc983;
  --red-color: #e0324f;
  --gray-color: #cccccc;

  --skeleton-bg: var(--secondary-bg);
  --skeleton-bg-dark: #e7e7e7;

  --avatar-dark-color: #e9e9e9;
  --avatar-light-color: #ffffff;
  --avatar-br: 50%;
  --avatar-ratio: 1/1;

  --medium-zoom-overlay-bg: #ffffff52;

  --container: 1440px;

  // ? Scrollbar
  ---track: var(--secondary-bg);
  --scroll-thumb: var(--tertiary-bg);
}

.dark:root {
  --primary-bg: #1b1b1b;
  --secondary-bg: #242424;
  --tertiary-bg: #2e2e2e;
  --line-color: #444444;

  --gray-color: #777777;

  --skeleton-bg: var(--secondary-bg);
  --skeleton-bg-dark: #1f1f1f;

  --avatar-dark-color: #2a2a2a;
  --avatar-light-color: #4a4a4a;

  --medium-zoom-overlay-bg: #00000052;
}
