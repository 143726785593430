@import 'media';

main.page,
footer.page {
  min-height: calc(100vh - 48px - 1rem);
  padding: 3rem 1rem;

  .container {
    & > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      h1,
      p {
        margin: 0;
      }
      p {
        margin-top: 0.5rem;
      }
    }
  }
}

.container {
  max-width: var(--container);
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
